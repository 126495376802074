import { render, staticRenderFns } from "./index.vue?vue&type=template&id=c118d598&scoped=true&"
import script from "./index.vue?vue&type=script&lang=js&"
export * from "./index.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c118d598",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Hint: require('/vercel/path0/components/application-forms/Hint.vue').default,ApplicationTextField: require('/vercel/path0/components/ui/forms/application/TextField.vue').default,ApplicationBaseInputField: require('/vercel/path0/components/ui/forms/application/_BaseInputField.vue').default,ApplicationButton: require('/vercel/path0/components/ui/ApplicationButton.vue').default,ModalLayout: require('/vercel/path0/components/ui/modal/ModalLayout.vue').default})
