
export default {
  layout: 'dashboard',

  nuxtI18n: {
    paths: {
      de: '/login',
      fr: '/login',
      it: '/login',
    },
  },

  data() {
    return {
      state: 'idle',

      email: '',
      emailConfirmation: '',
    }
  },

  head() {
    return {
      title: this.$t('login.page-title'),
    }
  },

  methods: {
    async onSubmit() {
      this.state = 'loading'

      try {
        await this.$axios.post('/applicants', {
          email: this.email,
        })

        await this.$axios.post('/applicants/send-verification-code', {
          email: this.email,
          redirectPath: this.localePath({ name: 'gesuche' }),
        })

        this.state = 'success'
      } catch (error) {
        this.state = 'error'

        console.error(error)
      }
    },
  },
}
