
import { useIdMixin } from '@teamnovu/utils'

export default {
  mixins: [useIdMixin({ name: 'uniqueId' })],

  inheritAttrs: false,

  props: {
    label: {
      type: String,
      default: '',
    },
    id: {
      type: String,
      default: '',
    },
    name: {
      type: String,
      default: '',
    },
    value: {
      type: [String, Number],
      default: '',
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    fullWidth: {
      type: Boolean,
      default: false,
    },
    alignRight: {
      type: Boolean,
      default: false,
    },
    prefix: {
      type: String,
      default: '',
    },
    postfix: {
      type: String,
      default: '',
    },
    maskType: {
      type: String,
      default: null,
    },
    unmaskActualValue: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      internalValue: this.maskValue(this.value),
    }
  },

  computed: {
    internalId() {
      return this.id || `text-${this.uniqueId}`
    },
  },

  watch: {
    internalValue() {
      this.$emit('input', this.unmaskValue(this.internalValue))
    },
    value() {
      this.internalValue = this.maskValue(this.value)
    },
  },

  methods: {
    maskValue(value) {
      if (value === null || value === undefined) {
        return ''
      }

      switch (this.maskType) {
        case 'currency':
          return value.toLocaleString('de-CH')
        default:
          return value
      }
    },

    unmaskValue(value) {
      if (value === null || value === undefined) {
        return ''
      }

      if (!this.unmaskValue) {
        return value
      }

      switch (this.maskType) {
        case 'currency':
          return value.replace(/[^0-9]/g, '')
        default:
          return value
      }
    },
  },
}
