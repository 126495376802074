
import debounce from 'lodash/debounce'

export default {
  props: {
    tag: {
      type: String,
      default: 'button',
    },
    role: {
      type: String,
      default: 'primary', // primary | secondary
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      debouncedIsLoading: this.isLoading,
    }
  },

  computed: {
    attributes() {
      return {
        type: this.tag === 'button' ? 'button' : undefined,

        'aria-disabled': this.$attrs.disabled,

        ...this.$attrs,
      }
    },

    loadingBgColor() {
      return {
        primary: 'bg-gray-900',
        secondary: 'bg-teal-700',
      }[this.role]
    },
  },

  watch: {
    isLoading: debounce(function (isLoading) {
      this.debouncedIsLoading = isLoading
    }, 50),
  },
}
